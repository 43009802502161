import $ from 'jquery/src/jquery'

var MS_ANIMATE = 250
var FIXED_LEFT_ROWS = 1
var START_BUNDLE = 0

var container = $('.price-container')
var tariffLine = $('.tariff_line')
var leftColumn = $('.left_column')
var scrollLeftButton = $('.scroll_control_left')
var scrollRightButton = $('.scroll_control_right')
var progressBar = $('.scroll_control_progress_bar')

window.price_scroll_timeout = null
window.price_manual_scroll_timeout = null
window.price_bundle = START_BUNDLE
window.rows_count = 0

function rowWidth() {
  return $(container.children()[1]).width()
}

function totalBundles() {
  return container.children().length - FIXED_LEFT_ROWS
}

function avoidCurrentBundleOverflow() {
  if (window.rows_count + window.price_bundle > totalBundles())
    window.price_bundle = totalBundles() - window.rows_count

  if (window.price_bundle < 0) window.price_bundle = 0
}

function setRowsCount() {
  window.rows_count = parseInt(container.width() / rowWidth(), 10)
  avoidCurrentBundleOverflow()
}

function setTariffsHeight() {
  tariffLine.height(leftColumn.height())
}

function addEmptyBar() {
  $('<div />', { 'class': 'scroll_control_progress_empty' }).appendTo(progressBar)
}

function addFilledBar() {
  $('<div />', { 'class': 'scroll_control_progress_filled' }).appendTo(progressBar)
}

function scrollBundles() {
  var left = rowWidth() * window.price_bundle

  tariffLine.animate({ scrollLeft: left }, MS_ANIMATE)
}

function renderProgress() {
  progressBar.empty()
  for (var i = 1; i <= window.price_bundle; i++) addEmptyBar()
  for (var i = 1; i <= window.rows_count; i++) addFilledBar()
  for (var i = 1; i <= totalBundles() - window.rows_count - window.price_bundle; i++) addEmptyBar()
}

function renderAll() {
  setTariffsHeight()
  setRowsCount()
  avoidCurrentBundleOverflow()
  renderProgress()
  scrollBundles()
}

$(window).on('resize', function() {
  if (!tariffLine || !container) return

  clearTimeout(window.price_scroll_timeout)
  window.price_scroll_timeout = setTimeout(renderAll, 100)
})

function manualScroll() {
  window.price_bundle = Math.round(Number($('.tariff_line').scrollLeft()) / Number(rowWidth()))

  avoidCurrentBundleOverflow()
  renderProgress()
}

tariffLine.scroll(function(e) {
  clearTimeout(window.price_manual_scroll_timeout)
  window.price_manual_scroll_timeout = setTimeout(manualScroll, 100)
})

$(document).ready(function() {
  if (!tariffLine || !container) return

  renderAll()

  scrollLeftButton.click(function() {
    window.price_bundle -= window.rows_count
    renderAll()
  })

  scrollRightButton.click(function() {
    window.price_bundle += window.rows_count
    renderAll()
  })
})
